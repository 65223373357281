import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col } from 'antd';
import Img from "gatsby-image";

const RAndD = () => {
    const data = useStaticQuery(graphql`
    {
        allAirtable(filter: {table: {eq: "dev"}}, sort: {fields: data___date, order: DESC}) {
          edges {
            node {
              data {
                postMarkdown {
                  childMarkdownRemark {
                    html
                    timeToRead
                  }
                }
                slug
                title
                snippet
                status
                image {
                  id
                  localFiles {
                    childImageSharp {
                      fluid(jpegProgressive: true, maxWidth: 368, maxHeight: 199) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `)
return (
    <Row className='teaser-grid' id="rand">
    { 
        data.allAirtable.edges.map(focus =>  {
            if (focus.node.data.status == 'publish') {
                const focusPath = `/r&d/${focus.node.data.slug}`;
                return (
                    <Col xs={24} lg={12} xl={8} key={focus.node.data.slug} className='teaser-grid-item'>
                    <Link to={focusPath}>
                        <Img fluid={focus.node.data.image.localFiles[0].childImageSharp.fluid} />
                        <h2>{focus.node.data.title}</h2>
                        <div className='focus_content' dangerouslySetInnerHTML={{ __html: focus.node.data.snippet }} /> 
                    </Link>
                    </Col>
                    )
                
            }
            })}
    </Row>
)}
    
    export default RAndD