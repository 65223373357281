import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Row } from 'antd';
import { Link } from "gatsby";
import RAndD from "../components/RAndD"

const Test = () => {
    return (
      <Layout>
        <div className="index-container" > 
          <Helmet title={config.siteTitle} />
          <SEO />
          <Row>
            <h1 style={{textAlign: `center`, marginBottom: `0`}}>Research and Development</h1>
            <p>These are some of our current and past development projects. Though we have some areas where we have named projects, we are generally looking to develop free, subsidized or low cost tools for poor folks to navigate and fight climate change and help build people powered autonomous infrastructure for liberation.</p>
            <p>We want to help both provide some of these things we're working on, but we're also excited about the spirit of open source and want to share our designs and ideas.</p>
            <p>Please connect with us if you have ideas! </p>

            <RAndD />
          </Row>
        </div>
      </Layout>
    )
  }

  export default Test;